// Import the table float script for this app to use

export default {
	bind(el) {
		el.classList.add('mk-tablefloat');
	},
	inserted(el) {
		// Bind the float functionality
		$(() => {
			$(el).mktablefloat && $(el).mktablefloat();
		});
	}
};
