import mdDate from '_utils_/mdDate';

const _ = {
	count: Symbol('private: label'),
	label: Symbol('private: label'),
	unit: Symbol('private: label'),
	unitOutput: Symbol('private: label')
};

export default class TimeframeOption {
	constructor(label, count, unit, unitOutput) {
		this[_.label] = label;
		this[_.count] = count;
		this[_.unit] = unit;
		this[_.unitOutput] = unitOutput;
	}

	get label() {
		return this[_.label];
	}

	get count() {
		return this[_.count];
	}

	get value() {
		return TimeframeOption.getValue(this[_.count], this[_.unit], this[_.unitOutput]);
	}

	toString() {
		return { label: this.label, value: this.value }.toString();
	}

	static getValue(count, unit, unitOutput) {
		const now = mdDate();
		const shifted = mdDate(now.date).subtract(count, unit);

		return now.diff(shifted, unitOutput);
	}
}
