const FOCUSABLE_SELECTOR = 'button:enabled, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const STATES = new WeakMap();

const STATE_KEYS = {
	isActive: Symbol('state: isActive'),
	isLoopValid: Symbol('state: isLoopValid'),
	listenerKeydown: Symbol('state: listenerKeydown'),
	loop: Symbol('state: loop')
};

function createFocusableLoop(el) {
	const allNodeList = el.querySelectorAll(FOCUSABLE_SELECTOR);

	const all = Array.prototype.filter.call(allNodeList, e => e !== el);
	// TODO: take tabindex value into account

	return {
		start: all[0],
		end: all[all.length - 1]
	};
}

function getFocusableLoop(el) {
	const state = STATES.get(el);

	if (state[STATE_KEYS.isLoopValid]) {
		return state[STATE_KEYS.loop];
	}

	const loop = createFocusableLoop(el);

	STATES.set(Object.assign(state, {
		[STATE_KEYS.loop]: loop,
		[STATE_KEYS.isLoopValid]: true
	}));

	return loop;
}

function captureLostFocus(elFrom, elTo, e) {
	if (document.activeElement === elFrom) {
		e.preventDefault();
		elTo.focus();
	}
}

function sendFocusForward(root, e) {
	const loop = getFocusableLoop(root);
	captureLostFocus(loop.end, loop.start, e);
}

function sendFocusBackward(root, e) {
	const loop = getFocusableLoop(root);
	captureLostFocus(loop.start, loop.end, e);
}

function corralTab(root, e) {
	e.shiftKey ? sendFocusBackward(root, e) : sendFocusForward(root, e);
}

function handleKeydown(el, e) {
	const keyCode = e.which || e.keyCode;

	if (keyCode === 9) {
		corralTab(el, e);
	}
}

function getListenerKeydown(el) {
	return handleKeydown.bind(undefined, el);
}

function addListenerKeydown(el) {
	const state = STATES.get(el);

	if (!state || !state[STATE_KEYS.listenerKeydown]) {
		const stateUpdate = { [STATE_KEYS.listenerKeydown]: getListenerKeydown(el) };
		const newState = Object.assign(state, stateUpdate);
		STATES.set(el, newState);
	}

	el.addEventListener('keydown', state[STATE_KEYS.listenerKeydown]);
}

function removeListenerKeydown(el) {
	const state = STATES.get(el);

	if (state && state[STATE_KEYS.listenerKeydown]) {
		el.removeEventListener('keydown', state[STATE_KEYS.listenerKeydown]);
	}
}

function getIsActiveFromValue(value) {
	return value !== false && value !== 'false' && value !== 'off';
}

function getInitialState(value) {
	return {
		[STATE_KEYS.isLoopValid]: false,
		[STATE_KEYS.listenerKeydown]: undefined,
		[STATE_KEYS.loop]: {},
		[STATE_KEYS.isActive]: getIsActiveFromValue(value)
	};
}

function captureFocus(el) {
	const state = STATES.get(el);

	if (!state[STATE_KEYS.isActive] ||
		el === document.activeElement ||
		el.contains(document.activeElement)) {
		return;
	}

	const loop = getFocusableLoop(el);

	if (loop.start) {
		loop.start.focus();
	}
}

export default {
	bind(el, binding, _vnode, _oldVnode) {
		STATES.set(el, getInitialState(binding.value));
		addListenerKeydown(el);
	},
	inserted(el, _binding, _vnode, _oldVnode) {
		captureFocus(el);
	},
	update(el, binding, _vnode, _oldVnode) {
		const stateUpdate = {
			[STATE_KEYS.isLoopValid]: false
		};

		if (binding.value !== binding.oldValue) {
			stateUpdate[STATE_KEYS.isActive] = getIsActiveFromValue(binding.value);
		}

		const state = STATES.get(el);
		STATES.set(el, Object.assign(state, stateUpdate));
	},
	componentUpdated(el, _binding, _vnode, _oldVnode) {
		captureFocus(el);
	},
	unbind(el, _binding, _vnode, _oldVnode) {
		removeListenerKeydown(el);
		STATES.delete(el);
	}
};
