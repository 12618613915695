import mdDate from '_utils_/mdDate';

function compareDates(dateA, dateB) {
	const mdDateA = mdDate(dateA);
	const mdDateB = mdDate(dateB);

	if (!mdDateB.isValid() || mdDateA.isBefore(mdDateB)) {
		return -1;
	}

	if (!mdDateA.isValid() || mdDateA.isAfter(mdDateB)) {
		return 1;
	}

	return 0;
}

function compareMaturityDates(dateA, dateB) {
	dateA = `01-${dateA.replace('-', '-20')}`;
	dateB = `01-${dateB.replace('-', '-20')}`;
	const mdDateA = mdDate(dateA);
	const mdDateB = mdDate(dateB);

	if (!mdDateB.isValid() || mdDateA.isBefore(mdDateB)) {
		return -1;
	}

	if (!mdDateA.isValid() || mdDateA.isAfter(mdDateB)) {
		return 1;
	}

	return 0;
}

function compareNumbers(numberA, numberB) {
	if (numberB === null || numberB === undefined) {
		return 1;
	}

	if (numberA === null || numberA === undefined) {
		return -1;
	}

	return numberA - numberB;
}

function compareStrings(stringA, stringB) {
	if (stringA > stringB || stringB === null || stringB === undefined) {
		return 1;
	}

	if (stringA < stringB || stringA === null || stringA === undefined) {
		return -1;
	}

	return 0;
}

function compareManyProps(a, b, props, comparator) {
	for (const prop of props) {
		const result = comparator(a[prop], b[prop]);
		if (result !== 0) {
			return result;
		}
	}

	return 0;
}

// plain functions
export const func = {
	dates: compareDates,
	numbers: compareNumbers,
	strings: compareStrings
};

// fluent API
export function compare(prop, callbackFun) {
	return {
		as: {
			dates: (a, b) => compareDates(a[prop], b[prop]),
			numbers: (a, b) => compareNumbers(a[prop], b[prop]),
			strings: (a, b) => compareStrings(a[prop], b[prop]),
			mDates: (a, b) => compareMaturityDates(a[prop], b[prop]),
			custom: (a, b) => callbackFun(a, b)
		}
	};
}

export function compareMany(...props) {
	return {
		as: {
			dates: (a, b) => compareManyProps(a, b, props, compareDates),
			numbers: (a, b) => compareManyProps(a, b, props, compareNumbers),
			strings: (a, b) => compareManyProps(a, b, props, compareStrings)
		}
	};
}

export default {
	func,
	compare,
	compareMany
};
