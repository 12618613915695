import Axios from 'axios';
import { default as gHelpler } from './globalHelpers';
const globalHelpers = gHelpler.globalHelpers;

export function requestProcess(config) {
	const params = window.location.search.replace('?', '').split('&');
	let apiDelay = 0;
	if (params && params.length) {
		params.forEach(param => {
			if (param.indexOf('..') !== -1) {
				if (param.indexOf('apidelay') !== -1) {
					apiDelay = param.replace('..apidelay..=', '');
				} else {
					config.url = config.url.indexOf('?') !== -1
						? `${config.url}&${param}`
						: `${config.url}?${param}`;
				}
			}
		});
	}
	return apiDelay;
}

export function delayReq(apiDelay, config) {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(config);
		}, apiDelay);
	});
}

export function responseProcess(response) {
	const o = null;
	const sanitized = response.data
		.replace('<!doctype html><html><body><pre>', '')
		.replace('</pre></body></html>', '')
		.replace('<script src="/asx-research-api/1.0/showdebuginfo/debug.js"></script>', '')
		.replace('<script>', '')
		.replace('</script>', '')
		.replace('Debug.url = "/asx-research-api/1.0/showdebuginfo/index.html?v=3.0.6";', '');

	/* eslint-disable */
	try {
		eval("o = " + sanitized);
	} catch (err) {
		console.log(err);
	}
	/* eslint-enable */

	response.data = o;

	return response.data;
}

export default {
	install(Vue) {
		if (!Axios) {
			throw Error('NPM install Axios');
		}
		const initialConfig = {
			baseURL: F2.IS_TEST_PAGE ? F2.API_DOMAIN.replace(/\/asx-research\//ig, '/asx-research-auth/') : F2.API_DOMAIN,
			timeout: 15000
		};

		if (F2.API_TOKEN && F2.API_DOMAIN.indexOf('localhost') === -1) {
			initialConfig.headers = {};
			initialConfig.headers['Authorization'] = `Bearer ${F2.API_TOKEN}`;
		}

		const axios = Axios.create(initialConfig);

		// Add a request interceptor
		axios.interceptors.request.use(config => {
			// Add all the .. params to the api requests
			const params = window.location.search.replace('?', '').split('&');
			let apiDelay = 0;
			if (params && params.length) {
				params.forEach(param => {
					if (param.indexOf('..') !== -1) {
						if (param.indexOf('apidelay') !== -1) {
							apiDelay = param.replace('..apidelay..=', '');
						} else {
							config.url = config.url.indexOf('?') !== -1
								? `${config.url}&${param}`
								: `${config.url}?${param}`;
						}
					}
				});
			}

			if ((config.baseURL.indexOf('cdn-api.markitdigital.com') >= 0 || config.baseURL.indexOf('cdn.qa-api.markitdigital.com') >= 0)) {
				config.params = config.params || {};
				switch (window.location.host.toLowerCase()) {
					case 'lite.asx.com.au':
						config.params.domain = 'lite';
						break;
					case 'cloud.marketing.asx.com.au':
						config.params.domain = 'cloudau';
						break;
					case 'cloud.marketing.asx.com':
						config.params.domain = 'cloud';
						break;
					case 'uabeta.asx.com.au':
						config.params.domain = 'uabeta';
						break;
					case 'asx.markitqa.com':
					case 'asx.markitdigital.com':
						config.params.domain = 'markit';
						break;
					default:
						break;
				}
			}

			if (apiDelay) {
				return delayReq(apiDelay, config);
			}

			return config;
		}, error => {
			// Do something with request error
			return Promise.reject(error);
		});

		// Add a response interceptor
		axios.interceptors.response.use(response => {
			// Add logic to return the data property to prevent the data.data check in the apps.
			if (response && response.data) {
				if (response.request.responseText.indexOf('Debug.add') !== -1) {
					const o = null;
					const sanitized = response.data
						.replace('<!doctype html><html><body><pre>', '')
						.replace('</pre></body></html>', '')
						.replace('<script src="/asx-research-api/1.0/showdebuginfo/debug.js"></script>', '')
						.replace('<script>', '')
						.replace('</script>', '')
						.replace('Debug.url = "/asx-research-api/1.0/showdebuginfo/index.html?v=3.0.6";', '');

					/* eslint-disable */
					try {
						eval("o = " + sanitized);
					} catch (err) {
						console.log(err);
					}
					/* eslint-enable */

					response.data = o;

					return response.data;
				}

				return response.data;
			}

			if (response.status === 201 || response.status === 204) {
				return Promise.resolve({});
			}

			// TODO: Determine what to return in this case.
			return Promise.reject(new Error('Invalid response'));
		}, error => {
			// Hook to handle errors
			return Promise.reject(error);
		});

		const authConfig = {
			baseURL: F2.API_DOMAIN ? F2.API_DOMAIN.replace(/\/asx-research\//ig, '/asx-research-auth/') : '',
			timeout: 15000
		};

		const authAxios = Axios.create(authConfig);
		F2.Events.on('refreshed-client-access-token', globalHelpers.refreshTokenAction);
		authAxios.interceptors.request.use(config => {
			globalHelpers.refreshTokenAction();
			const accessToken = globalHelpers.getRefreshedToken();
			if (accessToken) {
				config.headers.common['Authorization'] = `Bearer ${accessToken}`;
			}
			// Add all the .. params to the api requests
			const apiDelay = requestProcess(config);

			if (apiDelay) {
				return delayReq(apiDelay, config);
			}

			return config;
		}, error => {
			// Do something with request error
			return Promise.reject(error);
		});

		authAxios.interceptors.response.use(response => {
			// Add logic to return the data property to prevent the data.data check in the apps.
			if (response && response.data) {
				if (response.request.responseText.indexOf('Debug.add') !== -1) {
					return responseProcess(response);
				}

				return response.data;
			}


			if (response.status === 201 || response.status === 204) {
				return Promise.resolve({});
			}

			// TODO: Determine what to return in this case.
			return Promise.reject(new Error('Invalid response'));
		}, error => {
			// Hook to handle errors
			if (error.response && error.response.status === 401) {
				F2.Events.emit('update-client-access-token');
			}
			return Promise.reject(error);
		});

		Vue.prototype.$authHttp = authAxios;

		// Add the instance to vue
		Vue.prototype.$http = axios;

		// Add this as well in order to have the CancelToken and IsCancel functions avaiable.
		Vue.prototype.$httpHelpers = Axios;
	}
};
