const _ = {
	bindProxies: Symbol('bindProxies'),
	computed: Symbol('computed'),
	config: Symbol('config'),
	createInstance: Symbol('createInstance'),
	getProxyHandlers: Symbol('getProxyHandlers'),
	instance: Symbol('instance'),
	methods: Symbol('methods'),
	proxyHandlers: Symbol('proxyHandlers'),
	vue: Symbol('vue')
};

export default class MkVueAdapter {
	constructor(config) {
		this[_.config] = config;
	}

	get computed() {
		if (!this[_.computed]) {
			this[_.computed] = this[_.config].properties.reduce((props, k) => {
				props[k] = {
					cache: false,
					get() {
						const instance = this[_.instance];
						return typeof instance === 'object' ? instance[k] : undefined;
					}
				};

				return props;
			}, {});
		}

		return this[_.computed];
	}

	get methods() {
		if (!this[_.methods]) {
			this[_.methods] = this[_.config].methods.reduce((methods, k) => {
				methods[k] = function () {
					const instance = this[_.instance];
					return instance && typeof instance[k] === 'function'
						? instance[k](...arguments)
						: undefined;
				};

				return methods;
			}, {});
		}

		return this[_.methods];
	}

	init(vue) {
		this.unmount(vue);
		const instance = this[_.createInstance](vue);
		vue[_.instance] = instance;
		this[_.bindProxies](vue, instance);
	}

	instance(vue) {
		// TODO: replace this usage of symbols with weak map
		return vue[_.instance];
	}

	unmount(vue) {
		if (vue[_.instance]) {
			vue[_.instance].unmount();
			vue[_.instance] = null;
		}
	}

	[_.bindProxies](vue, instance) {
		const handlers = this[_.getProxyHandlers](vue);
		Object.keys(handlers).forEach(evt => {
			instance.on(evt, handlers[evt]);
		});
	}

	[_.createInstance](vue) {
		const instance = this[_.config].create(vue);
		instance[_.vue] = vue;
		return instance;
	}

	[_.getProxyHandlers](vue) {
		return (this[_.config].events || []).reduce((map, evt) => {
			map[evt] = data => vue.$emit(evt.replace('.', '-'), data);
			return map;
		}, {});
	}
}
