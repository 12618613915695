/**
 * Use as a Vue plugin:
 *
 * import WidgetEvent from '_share_/plugins/widgetEvent';
 * Vue.use(WidgetEvent);
 *
 * In component files:
 * Within <template>: $widgetEvent.<FUNCTION>
 * Within <script>: this.$widgetEvent.<FUNCTION>
 *
 * Use elsewhere:
 *
 * import WidgetEvent from '_share_/plugins/widgetEvent';
 * const $widgetEvent = WidgetEvent.events;
 * Call with $widgetEvent.<FUNCTION>
 *
 */

import mdDate from '_utils_/mdDate';

const F2_EVENT_NAME = 'widget_emit';

function emitF2Events(eventName, widgetID, category, action, label, value) {
	const eventInfo = {
		event: eventName,
		widgetID,
		eventCategory: category,
		eventAction: action,
		eventLabel: label,
		eventValue: value,
		timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
	};

	F2.Events.emit(F2_EVENT_NAME, eventInfo);
}

const WIDGET_ID_LIST = {
	COMPANY_SEARCH: 'com_asx_company_search',
	HOME_MARKETS: 'com_asx_home_markets',
	HOME_TOP_FIVE: 'com_asx_home_top_five',
	HOME_TOP_FIFTY: 'com_asx_home_top50_market_cap',
	HOME_SECTORS: 'com_asx_home_sectors',
	HOME_ANNOUNCEMENTS: 'com_asx_home_announcements',
	HOME_INVESTMENT_IDEAS: 'com_asx_home_investment_ideas',
	COMPANY_HEADER: 'com_asx_company_header',
	COMPANY_KEY_STATISTICS: 'com_asx_company_key_statistics',
	COMPANY_KEY_STATISTICS_CHARTS: 'com_asx_company_key_statistics_charts',
	COMPANY_ANNOUNCEMENTS: 'com_asx_company_announcements',
	COMPANY_PEERS: 'com_asx_company_peers',
	COMPANY_PRODUCTS: 'com_asx_company_products',
	COMPANY_ABOUT: 'com_asx_company_about',
	COMPANY_PRICE_CHART: 'com_asx_company_price_chart',
	COMPANY_OPTIONS: 'com_asx_company_options',
	COMPANY_WARRANTS: 'com_asx_company_warrants',
	COMPANY_BONDS: 'com_asx_company_bonds',
	COMPANY_HYBRIDS: 'com_asx_company_hybrids',
	ETF_HEADER: 'com_asx_etf_header',
	ETF_KEY_STATISTICS: 'com_asx_etf_key_statistics',
	ETF_KEY_STATISTICS_CHARTS: 'com_asx_etf_key_statistics_charts',
	ETF_FUND_ALLOCATION: 'com_asx_etf_fund_allocation',
	ETF_TOP_HOLDINGS: 'com_asx_etf_top_holdings',
	ETF_ABOUT: 'com_asx_etf_about',
	ETF_SIMILAR_FUNDS: 'com_asx_etf_similar_funds',
	COMPANY_DIRECTORY: 'com_asx_company_directory',
	BONDS_GOVERNMENT: 'com_asx_bonds_government',
	BONDS_CORPORATE: 'com_asx_bonds_corporate',
	HYBRIDS_CONVERTIBLE_NOTES: 'com_asx_hybrids_convertible_notes',
	HYBRIDS_PREFERENCE_SHARES: 'com_asx_hybrids_preference_shares',
	HYBRIDS_CAPITAL_NOTES: 'com_asx_hybrids_capital_notes',
	MARKETS_ANNOUNCEMENTS: 'com_asx_markets_announcements',
	WATCHLIST: 'com_asx_watchlist',
	HOME_LATESTSYMBOLS: 'com_asx_home_latestsymbols',
	HOME_EVENTS: 'com_asx_home_events',
  EQUITY_PRICING: 'com_asx_equity_pricing'

};

const companySearch = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Details - in page company search',
		action = 'Search Company',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.COMPANY_SEARCH,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const homeMarkets = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Home Page Markets',
		action = 'Click Tab / change tab',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.HOME_MARKETS,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const homeTopFive = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Home Page ASX200 Top 5',
		action = 'Select Company Link',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.HOME_TOP_FIVE,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};
const homeTopFifty = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Home Page ASX200 Top 50',
		action = 'Select Company Link',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.HOME_TOP_FIFTY,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const homeSectors = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Home Page Sectors',
		action = 'Change time period',
		label = '',
		value = '1'
	) {
		emitF2Events(eventName, WIDGET_ID_LIST.HOME_SECTORS, category, action, label, value);
	}
};

const announcements = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		widgetID = WIDGET_ID_LIST.HOME_ANNOUNCEMENTS,
		category = 'Home Page Price Sensitive Announcements',
		action = 'Open Announcement',
		label = '',
		value = '1'
	) {
		emitF2Events(eventName, widgetID, category, action, label, value);
	}
};

const homeInvestmentIdeas = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Home Page Market Insights',
		action = 'Click Tab / change tab',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.HOME_INVESTMENT_IDEAS,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const companyHeader = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Information Page - Shares prices',
		action = 'Page Load',
		label = '',
		value = ''
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.COMPANY_HEADER,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const options = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		widgetID = WIDGET_ID_LIST.COMPANY_OPTIONS,
		category = 'Company Information Page - Options tab',
		action = 'Pick from Dropdown',
		label = '',
		value = '1',
		emit = true
	) {

		const eventInfo = {
			event: eventName,
			widgetID,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		if (emit) {
			F2.Events.emit(F2_EVENT_NAME, eventInfo);
		}
	}
};

const companyKeyStatistics = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		widgetID = WIDGET_ID_LIST.COMPANY_KEY_STATISTICS,
		category = 'Company Information Page - Shares - Fundamentals',
		action = 'Page Load',
		label = '',
		value = ''
	) {

		emitF2Events(eventName, widgetID, category, action, label, value);
	}
};

const companyWarrants = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		widgetID = WIDGET_ID_LIST.COMPANY_WARRANTS,
		category = 'Company Information Page - Warrants tab',
		action = 'Pick from Dropdown',
		label = '',
		value = '1'
	) {
		emitF2Events(eventName, widgetID, category, action, label, value);
	}
};
const bonds = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		widgetID = WIDGET_ID_LIST.BONDS_GOVERNMENT,
		category = 'bonds',
		action = 'Click Tab / change tab',
		label = '',
		value = '1'
	) {
		emitF2Events(eventName, widgetID, category, action, label, value);
	}
};

const companyPeers = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Information Page - Shares - Peer Analysis App',
		action = 'Page Load',
		label = '',
		value = ''
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.COMPANY_PEERS,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const hybrids = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		widgetID = WIDGET_ID_LIST.HYBRIDS_CONVERTIBLE_NOTES,
		category = 'Hybrids',
		action = 'Open Modal',
		label = '',
		value = '1'
	) {
		emitF2Events(eventName, widgetID, category, action, label, value);
	}
};

const pageLoad = {
	widgetEvent(
		widgetID = WIDGET_ID_LIST.ETF_HEADER,
		category = 'Company Information Page - ETFs - Quotes Strip'
	) {

		const eventInfo = {
			event: 'Markit Widget Event',
			widgetID,
			eventCategory: category,
			eventAction: 'Page Load',
			eventLabel: '',
			eventValue: '',
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};
		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const companyProducts = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Details (used on the Company Info page) > tabs',
		action = 'Click Tab / change tab',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.COMPANY_PRODUCTS,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const etfHoldings = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Information Page - ETFs - Top holdings',
		action = 'Select Company Link',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.ETF_TOP_HOLDINGS,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const companyAbout = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Information Page - Shares - Company About',
		action = 'Click External Link',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.COMPANY_ABOUT,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const etfAbout = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Information Page - ETFs - About',
		action = 'Click External Link',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.ETF_ABOUT,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const watchlist = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Watchlist',
		action = 'Click Tab / Change tab',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.WATCHLIST,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const chartworks = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		widgetID = WIDGET_ID_LIST.COMPANY_PRICE_CHART,
		category = 'Company Information Page - Shares - pricing chart',
		action = 'Chart Print or Download',
		label = 'Company Page chart interaction',
		value = '1'
	) {
		emitF2Events(eventName, widgetID, category, action, label, value);
	}
};

const etfSimilarFunds = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Information Page - ETFs - Similar Funds',
		action = 'Select ETF Link',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.ETF_SIMILAR_FUNDS,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};

const personal = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		widgetID = WIDGET_ID_LIST.COMPANY_HEADER,
		category = 'Personalised Company Page',
		action = 'Click Button',
		label = '',
		value = '1'
	) {
		emitF2Events(eventName, widgetID, category, action, label, value);
	}
};

const companyDirectory = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Company Directory',
		action = 'Download File',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.COMPANY_DIRECTORY,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};
		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};
// equity pricing
const com_asx_equity_pricing = {
	widgetEvent(
		eventName = 'Markit Widget Event',
		category = 'Home Page ASX200 Top 50',
		action = 'Select Company Link',
		label = '',
		value = '1'
	) {

		const eventInfo = {
			event: eventName,
			widgetID: WIDGET_ID_LIST.EQUITY_PRICING,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: value,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(F2_EVENT_NAME, eventInfo);
	}
};
const customEvent = {
	on(
		eventName = 'Custom Event',
		eventObj = {}
	) {

		const eventInfo = { ...eventObj,
			timestamp: mdDate().tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')
		};

		F2.Events.emit(eventName, eventInfo);
	}
};

const events = {
	WIDGET_ID_LIST,
	companySearch: companySearch.widgetEvent,
	announcements: announcements.widgetEvent,
	homeMarkets: homeMarkets.widgetEvent,
	homeInvestmentIdeas: homeInvestmentIdeas.widgetEvent,
	homeSectors: homeSectors.widgetEvent,
	homeTopFive: homeTopFive.widgetEvent,
	homeTopFifty: homeTopFifty.widgetEvent,
	companyHeader: companyHeader.widgetEvent,
	companyKeyStatistics: companyKeyStatistics.widgetEvent,
	companyPeers: companyPeers.widgetEvent,
	companyProducts: companyProducts.widgetEvent,
	companyAbout: companyAbout.widgetEvent,
	chartworks: chartworks.widgetEvent,
	options: options.widgetEvent,
	companyWarrants: companyWarrants.widgetEvent,
	bonds: bonds.widgetEvent,
	pageLoad: pageLoad.widgetEvent,
	etfHoldings: etfHoldings.widgetEvent,
	etfAbout: etfAbout.widgetEvent,
	etfSimilarFunds: etfSimilarFunds.widgetEvent,
	companyDirectory: companyDirectory.widgetEvent,
	hybrids: hybrids.widgetEvent,
	watchlist: watchlist.widgetEvent,
	personal: personal.widgetEvent,
  com_asx_equity_pricing: com_asx_equity_pricing.widgetEvent,
	customEvent: customEvent.on
};

export default {
	install(Vue) {
		Object.defineProperty(Vue.prototype, '$widgetEvent', { value: events });
	},
	events
};
