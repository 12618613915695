const STATES = new WeakMap();

const DATA_KEYS = {
	returnTarget: Symbol()
};

function setReturnTarget(el, attributeValue) {
	if (attributeValue === false) {
		STATES.set(el, { [DATA_KEYS.returnTarget]: undefined });
		return;
	}

	const returnTarget = attributeValue && attributeValue instanceof HTMLElement
		? attributeValue
		: document.activeElement;

	STATES.set(el, { [DATA_KEYS.returnTarget]: returnTarget });
}

export default {
	bind(el, binding, _vnode, _oldVnode) {
		setReturnTarget(el, binding.value);
	},
	update(el, binding, _vnode, _oldVnode) {
		if (binding.value !== binding.oldValue) {
			setReturnTarget(el, binding.value);
		}
	},
	unbind(el, _binding, _vnode, _oldVnode) {
		const state = STATES.get(el);

		const returnTarget = state[DATA_KEYS.returnTarget];

		if (returnTarget) {
			returnTarget.focus();
		}

		STATES.delete(el);
	}
};
