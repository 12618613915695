import mdDate from '_utils_/mdDate';
import numeral from 'numeral';

const DATE_FORMAT = 'DD/MM/YYYY';
const NO_VALUE = '--';
const NUMBER_FORMAT = '0,0.00';
const PERCENT_FORMAT = '0.00';
const PRICE_FORMAT = '$0,0.00';
const TIME_FORMAT = 'h:mm:ss a';
const TIME_FORMAT1 = 'h:mm a';
const PAY_FREQUENCY_BY_CODE = {
	[1]: 'Yearly',
	[2]: 'Half Yearly',
	[4]: 'Quarterly',
	[12]: 'Monthly'
};

const colorSequence = [
	'#0095C8',
	'#0C3B6C',
	'#89A0B3',
	'#215EAC',
	'#00A58E',
	'#38B249',
	'#AFDBCE',
	'#FFC649',
	'#EF4437',
	'#CDCBD5',
	'#02032F'
];

// set the locales
numeral.locale('en');

// set the abbreviations
numeral.locales['en'].abbreviations = {
	thousand: 'K',
	million: 'M',
	billion: 'B',
	trillion: 'T'
};

function sanitizeNumber(val) {
	// This was added to get around a bug in numeral for numbers like 1.176083712526649e-14.
	if (val && val.toString().indexOf('e') > -1) {
		// It is a valid number but a very tiny one at that, just return 0.
		return 0;
	}

	return val;
}

const isValid = val => {
	return !(val === undefined || val === null || val === -32768 || val === '-32768');
};

const isValidNumber = val => {
	return isValid(val) && !isNaN(val);
};

const isValidText = val => {
	return isValid(val) && val !== null && val !== '';
};

const date = (val, options) => {
	if (!isValid(val)) {
		return NO_VALUE;
	}

	options = options || {};

	const m = mdDate(val);

	return m.format(options.formatString || DATE_FORMAT);
};
const time = (val, options) => {
	if (!isValid(val)) {
		return NO_VALUE;
	}

	options = options || {};

	const m = mdDate(val);

	return m.format(options.formatString || TIME_FORMAT1);
};

const utcDate = (val, options) => {
	if (!isValid(val)) {
		return NO_VALUE;
	}

	options = options || {};

	const m = mdDate(val).utc();

	return m.format(options.formatString || DATE_FORMAT);
};

const dateRelativeDays = (val, options) => {
	if (!isValid(val)) {
		return NO_VALUE;
	}

	const m = mdDate(val);
	const today = mdDate();

	if (m.isSame(today, 'day')) {
		return 'Today';
	}

	const yesterday = mdDate().subtract(1, 'd');

	if (m.isSame(yesterday, 'day')) {
		return 'Yesterday';
	}

	const tomorrow = mdDate().add(1, 'd');

	if (m.isSame(tomorrow, 'day')) {
		return 'Tomorrow';
	}

	options = options || {};

	return m.format(options.formatString || DATE_FORMAT);
};

const dateTime = (val, options) => {
	if (!isValid(val)) {
		return NO_VALUE;
	}

	options = options || {};

	const m = mdDate(val);

	return `${m.format(options.dateFormatString || DATE_FORMAT)} ${m.format(options.timeFormatString || TIME_FORMAT)}`;
};


const dateFromNow = val => {
	if (!isValid(val)) {
		return NO_VALUE;
	}
	const m = mdDate(val);
	return m.from(mdDate());
};

const magnitude = (val, options) => {
	if (!isValidNumber(val)) {
		return NO_VALUE;
	}

	val = sanitizeNumber(val);

	options = options || {};

	const prependString = options.prependString || '';
	const formatString = options.formatString || '0.00a';

	if (options.format === 'full') {
		return `${prependString}${numeral(val).format(formatString, Math.floor).replace('B', ' Billion').replace('M', ' Million')}`;
	}

	return `${prependString}${numeral(val).format(formatString, Math.floor)}`;
};

const number = (val, options) => {
  // return -- if [0 OR --]
  if (options && options.showHyphenIfZero && (val === 0 || val === '--')) {
    return '--'
  }
	if (!isValidNumber(val)) {
		return NO_VALUE;
	}

	val = sanitizeNumber(val);

	options = options || {};

	if (options.showSign && val > 0) {
		options.formatString = `+${options.formatString || NUMBER_FORMAT}`;
	}

	return numeral(val).format(options.formatString || NUMBER_FORMAT, val >= 0 ? Math.floor : Math.ceil);
};

const priceRoundOff = (val, options) => {
	if (!isValidNumber(val)) {
		return NO_VALUE;
	}

	val = sanitizeNumber(val);

	options = options || {};

	if (options.showSign && val > 0) {
		options.formatString = `+${options.formatString || NUMBER_FORMAT}`;
	}

	return numeral(val).format(options.formatString || NUMBER_FORMAT, Math.round);
};

const fileSize = (val, options) => {
	if (!isValidNumber(val)) {
		return NO_VALUE;
	}

	val = sanitizeNumber(val);

	return number(val, Object.assign({}, options, { formatString: '0.0b' }));
};

const payFrequency = val => {
	return PAY_FREQUENCY_BY_CODE[val] || NO_VALUE;
};

const percent = (val, options = {}) => {
	if (!isValidNumber(val)) {
		return NO_VALUE;
	}

	const formatString = options.formatString || PERCENT_FORMAT;

	options = Object.assign({}, options, { formatString: `${formatString}%` });

	return number(val / 100, options);
};

const price = (val, options) => {
	if (!isValidNumber(val)) {
		return NO_VALUE;
	}

	val = sanitizeNumber(val);

	const formatString = options && options.formatString
		? options.formatString
		: PRICE_FORMAT;

	return numeral(val.toFixed(4)).format(formatString, Math.floor);
};

const priceMagnitude = (val, options) => {
	if (!isValidNumber(val)) {
		return NO_VALUE;
	}

	val = sanitizeNumber(val);

	options = options || {};

	if (options.format === 'full') {
		return numeral(val).format('$0.00a').replace('B', ' Billion').replace('M', ' Million');
	}

	return numeral(val).format('$0.00a', Math.floor);
};

const priceRange = (valLow, valHigh, options) => {
	if (!isValidNumber(valLow) || !isValidNumber(valHigh)) {
		return NO_VALUE;
	}

	return `${price(valLow, options)} - ${price(valHigh, options)}`;
};
const text = val => {
	if (!isValidText(val)) {
		return NO_VALUE;
	}

	return val;
};

const textFirstLetterCapitalize = val => {
	if (!isValidText(val)) {
		return NO_VALUE;
	}

	return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
};

const sanitize = str => {
	const map = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		"'": '&#x27;',
		'/': '&#x2F;'
	};
	const reg = /[&<>"'/]/ig;
	return str.replace(reg, match => (map[match]));
};

export default {
	NO_VALUE,
	colorSequence,
	date,
	time,
	utcDate,
	dateFromNow,
	dateRelativeDays,
	dateTime,
	fileSize,
	isValid,
	isValidNumber,
	isValidText,
	magnitude,
	number,
	payFrequency,
	percent,
	price,
	priceMagnitude,
	priceRange,
	priceRoundOff,
	text,
	textFirstLetterCapitalize,
	sanitize
};
