export default {
	transferMembers(ctx, source, target) {
		target = Object.assign({}, target || {});

		Object.keys(source).forEach(k => {
			const member = source[k];

			if (member === undefined) {
				return;
			}

			if (typeof member === 'function') {
				target[k] = member.bind(ctx);
			} else if (typeof member === 'object') {
				target[k] = Object.assign({}, member);
			} else {
				target[k] = member;
			}

		});

		return target;
	},

	addClassToVnode(className, vnode) {
		if (!vnode) {
			return;
		}

		if (!vnode.data) {
			vnode.data = {};
		}

		if (!vnode.data.staticClass) {
			vnode.data.staticClass = '';
		}

		const space = vnode.data.staticClass.length ? ' ' : '';

		const existingClasses = vnode.data.staticClass.split(' ');

		if (existingClasses.indexOf(className) === -1) {
			vnode.data.staticClass = `${vnode.data.staticClass}${space}${className}`;
		}
	},
	isShowTheoreticalPrice(volume, value) {
		let showTheoreticalPrice = false;
		if (volume === null || volume === 0 || volume === undefined || (!(value === null && value === undefined) && value <= 0)) {
			showTheoreticalPrice = true;
		}
		return showTheoreticalPrice;
	}
};
