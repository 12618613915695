// TODO: Add unit tests
const STATES = new WeakMap();

const STATE_KEYS = {
	loader: { key: 'loader' },
	handlerResize: { key: 'handlerResize' }
};

const IMAGE_PATH = '/images/loader.gif';

const STYLES_BASE = {
	position: 'absolute',
	backgroundImage: `url(${F2.MANIFEST_DOMAIN + IMAGE_PATH})`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	transition: 'opacity 0.1s ease-in-out',
	backgroundColor: 'rgba(240, 240, 240, .7)',
	top: 0,
	left: 0,
	height: 0,
	width: 0,
	'z-index': 999
};

const STYLES_DARK = Object.assign({}, STYLES_BASE, {
	backgroundColor: 'rgba(240, 240, 240)'
});

let IS_STYLE_INITTED;
const STYLE_RULES = [
	'.v-loader-container { opacity: 0; }',
	'.v-loader-container.live { opacity: 1; }',
	'.v-loader-container.live.dark { opacity: 0.7; }'
];

function initStyles() {
	if (IS_STYLE_INITTED) { return; }
	const styleNode = document.createElement('style');
	document.head.appendChild(styleNode);
	STYLE_RULES.forEach(rule => styleNode.sheet.insertRule(rule));
	IS_STYLE_INITTED = true;
}

function getState(el, prop) {
	if (!STATES.has(el)) {
		return undefined;
	}

	const state = STATES.get(el);
	return prop !== undefined ? state.get(prop) : state;
}

function setState(el, prop, val) {
	if (!STATES.has(el)) {
		STATES.set(el, new WeakMap());
	}

	const state = getState(el);
	state.set(prop, val);
}

function deleteState(el, prop) {
	const state = getState(el);

	if (!hasState(el)) {
		return;
	}

	if (prop === undefined) {
		STATES.delete(el);
		return;
	}

	state.delete(prop);
}

function hasState(el, prop) {
	return prop === undefined
		? STATES.has(el)
		: STATES.has(el) && STATES.get(el).has(prop);
}

function createLoader(_el, binding = {}) {
	const loader = document.createElement('div');

	const isDark = binding.modifiers && binding.modifiers.dark;

	loader.classList.add('v-loader-container');

	if (binding.modifiers && binding.modifiers.dark) {
		loader.classList.add('dark');
	}

	const styles = isDark ? STYLES_DARK : STYLES_BASE;

	Object.assign(loader.style, styles);

	return loader;
}

function handleResize(params, _e) {
	if (params.timer) {
		clearTimeout(params.timer);
	}

	params.timer = setTimeout(() => {
		positionLoader(params.el);
	});
}

function createHandlerResize(el) {
	return handleResize.bind({ el });
}

function listenResize(el) {
	if (!hasState(el, STATE_KEYS.handlerResize)) {
		setState(el, STATE_KEYS.handlerResize, createHandlerResize(el));
	}

	window.addEventListener('resize', getState(el, STATE_KEYS.handlerResize));
}

function stopListenResize(el) {
	if (hasState(el, STATE_KEYS.handlerResize)) {
		window.removeEventListener('resize', getState(el, STATE_KEYS.handlerResize));
	}
}

function getPositionStyles(el) {
	return {
		height: `${el.offsetHeight}px`,
		width: `${el.offsetWidth}px`,
		top: `${el.offsetTop}px`,
		left: `${el.offsetLeft}px`
	};
}

function positionLoader(el) {
	if (hasState(el, STATE_KEYS.loader)) {
		Object.assign(getState(el, STATE_KEYS.loader).style, getPositionStyles(el));
	}
}

function showLoader(el, binding) {
	if (!hasState(el, STATE_KEYS.loader)) {
		setState(el, STATE_KEYS.loader, createLoader(el, binding));
	}

	positionLoader(el);

	const loader = getState(el, STATE_KEYS.loader);

	if (!loader.parentElement && el.parentElement) {
		el.setAttribute('aria-busy', true);
		el.parentElement.appendChild(loader);

		listenResize(el);
	}

	setTimeout(() => loader.classList.add('live'));
}

function hideLoader(el) {
	const loader = getState(el, STATE_KEYS.loader);

	loader && loader.classList.remove('live');

	if (loader && loader.parentElement) {
		loader.parentElement.removeChild(loader);
		el.removeAttribute('aria-busy');
		stopListenResize(el);
	}
}

function toggleLoader(el, binding) {
	if (binding.value === true || binding.value === undefined) {
		showLoader(el, binding);
	} else {
		hideLoader(el);
	}
}

function handleUpdate(el, binding, vnode, _oldVnode) {
	toggleLoader(el, binding);
	positionLoader(el);

	vnode.context.$nextTick(() => {
		positionLoader(el);
	});
}

export default {
	inserted(el, binding, vnode, _oldVnode) {
		initStyles();
		toggleLoader(el, binding);
		positionLoader(el);

		vnode.context.$nextTick(() => {
			positionLoader(el);
		});
	},
	update(el, binding, vnode, oldVnode) {
		handleUpdate(el, binding, vnode, oldVnode);
	},
	componentUpdated(el, binding, vnode, oldVnode) {
		handleUpdate(el, binding, vnode, oldVnode);
	},
	unbind(el, _binding, _vnode, _oldVnode) {
		hideLoader(el);
		deleteState(el);
	}
};
