export default {
	install(Vue, _options) {
		Vue.prototype.$track = {
			pageView: trackData => {
				const data = {
					category: 'DIP',
					name: trackData.name,
					label: trackData.label,
					value: trackData.value,
					page: 'F2AppView'
				};

				try {
					window._waq.push(['_trackPageView', data]);
				} catch (e) {
					// do nothing
				}
			}
		};
	}
};
