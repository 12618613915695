/*
 * Written By
 * MANISH GUPTA
 */

const i18n = {
	dayNames: [
		'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
		'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
	],
	monthNames: [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
		'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
	]
};

const token = /D{1,4}|M{1,4}|YY(?:YY)?|([HhmsAa])\1?|[LloSz]|"[^"]*"|'[^']*'/g;
const timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g;
const timezoneClip = /[^-+\dA-Z]/g;
const DEFAULT_FORMAT = 'DD/MM/YYYY';

const defaultRelativeTime = {
	s: 'a few seconds',
	ss: '%d seconds',
	m: 'a minute',
	mm: '%d minutes',
	h: 'an hour',
	hh: '%d hours',
	d: 'a day',
	dd: '%d days',
	M: 'a month',
	MM: '%d months',
	y: 'a year',
	yy: '%d years'
};

const pad = (val, len) => {
	val = String(val);
	len = len || 2;
	while (val.length < len) val = `0${val}`;
	return val;
};

const getAUTimeZoneAbbrName = date => {
	date = new Date(date);
	if (isNaN(date)) throw new Error('invalid date');

	// Set to start of the month when DST starts (October) and time of rollover (0200)
	const dstStart = new Date(date.getUTCFullYear(), 10 - 1, 1, 2);

	// Set to start of the month when DST ends (April) and time of rollover (0300)
	const dstEnd = new Date(date.getUTCFullYear(), 4 - 1, 1, 3);

	// calculate the position of the first Sunday of the start and end month
	dstStart.setDate(dstStart.getDate() + ((7 - dstStart.getDay()) % 7));
	dstEnd.setDate(dstEnd.getDate() + ((7 - dstEnd.getDay()) % 7));

	const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;

	if (utcTime > dstEnd.getTime() && utcTime < dstStart.getTime()) {
		// No DST!
		return 'AEST';
	}
	// Yes, DST!
	return 'AEDT';
};

const getTimeZone = mdDate => {
	let timeZone = '';
	if (mdDate.convertedToDiffTimeZone === false && mdDate.timeZone === 'local') {
		timeZone = (String(mdDate.date).match(timezone) || ['']).pop().replace(timezoneClip, '');
	} else {
		switch (mdDate.timeZone) {
			case 'Australia/Sydney':
				timeZone = getAUTimeZoneAbbrName(mdDate.initialDate);
				break;
			default:
				timeZone = (String(mdDate.date).match(timezone) || ['']).pop().replace(timezoneClip, '');
				break;
		}
	}
	return timeZone;
};

const invalidDate = () => {
	return 'Invalid date';
};

function daysToMonths(days) {
	// 400 years have 146097 days (taking into account leap year rules)
	// 400 years have 12 months === 4800
	return days * 4800 / 146097;
}

function monthsToDays(months) {
	// the reverse of daysToMonths
	return months * 146097 / 4800;
}

function positiveDifference(base, other) {
	const res = {};

	res.months = other.date.getMonth() - base.date.getMonth() +
			(other.date.getFullYear() - base.date.getFullYear()) * 12;

	if (base.clone().add(res.months, 'months').isAfter(other)) {
		--res.months;
	}

	res.milliseconds = other.date.getTime() - (base.clone().add(res.months, 'months')).date.getTime();

	return res;
}

function datesDifference(base, other) {
	return positiveDifference(base, other);
}

function getUnitValue(diffObj, units) {
	diffObj.milliseconds = diffObj.milliseconds || 0;
	diffObj.days = diffObj.days || 0;
	diffObj.months = diffObj.months || 0;
	let days;
	let months;
	const milliseconds = diffObj.milliseconds;

	if (units === 'month' || units === 'quarter' || units === 'year') {
		days = diffObj.days + milliseconds / 864e5;
		months = diffObj.months + daysToMonths(days);
		switch (units) {
			case 'month': return months;
			case 'quarter': return months / 3;
			case 'year': return months / 12;
			default: return months;
		}
	} else {
		days = diffObj.days + Math.round(monthsToDays(diffObj.months));
		switch (units) {
			case 'week' : return days / 7 + milliseconds / 6048e5;
			case 'day' : return days + milliseconds / 864e5;
			case 'hour' : return days * 24 + milliseconds / 36e5;
			case 'minute' : return days * 1440 + milliseconds / 6e4;
			case 'second' : return days * 86400 + milliseconds / 1000;
				// Math.floor prevents floating point math errors here
			case 'millisecond': return Math.floor(days * 864e5) + milliseconds;
			default: throw new Error(`Unknown unit ${units}`);
		}
	}
}

function getRelativeTimeStr(diffObj, isFuture) {

	const thresholds = {
		ss: 44, // a few seconds to seconds
		s: 45, // seconds to minute
		m: 45, // minutes to hour
		h: 22, // hours to day
		d: 26, // days to month
		M: 11 // months to year
	};

	const seconds = Math.round(getUnitValue(diffObj, 'second'));
	const minutes = Math.round(getUnitValue(diffObj, 'minute'));
	const hours = Math.round(getUnitValue(diffObj, 'hour'));
	const days = Math.round(getUnitValue(diffObj, 'day'));
	const months = Math.round(getUnitValue(diffObj, 'month'));
	const years = Math.round(getUnitValue(diffObj, 'year'));

	const a = seconds <= thresholds.ss && ['s', seconds] ||
	seconds < thresholds.s && ['ss', seconds] ||
	minutes <= 1 && ['m'] ||
	minutes < thresholds.m && ['mm', minutes] ||
	hours <= 1 && ['h'] ||
	hours < thresholds.h && ['hh', hours] ||
	days <= 1 && ['d'] ||
	days < thresholds.d && ['dd', days] ||
	months <= 1 && ['M'] ||
	months < thresholds.M && ['MM', months] ||
	years <= 1 && ['y'] || ['yy', years];
	if (a.length === 0) {
		return 'a few seconds';
	}
	return defaultRelativeTime[a[0]].replace(/%d/i, a[1]);
}

function MDDate(_date, useProvidedDateOnly) {
	if (useProvidedDateOnly) {
		this.date = new Date(_date);
	} else {
		this.date = !isNaN(new Date(_date)) ? new Date(_date) : new Date();
	}
	this.timeZone = 'local';
	this.convertedToDiffTimeZone = false;
	this.isUTC = false;
	this.initialDate = new Date(this.date);
}

MDDate.prototype.subtract = function (number, type) {
	switch (type ? type.toLowerCase() : '') {
		case 'd' :
		case 'day' :
		case 'days' :
		{
			this.date.setDate(this.date.getDate() - number);
			break;
		}
		case 'week' :
		case 'weeks':
		{
			this.date.setDate(this.date.getDate() - (number * 7));
			break;
		}
		case 'month' :
		case 'months' :
		{
			const month = this.date.getMonth();
			this.date.setMonth(month - number);
			// Fixing last day of the month errors
			while (this.date.getMonth() === month) {
				this.date.setDate(this.date.getDate() - 1);
			}
			break;
		}
		case 'year' :
		case 'years' :
		{
			this.date.setFullYear(this.date.getFullYear() - number);
			break;
		}
		default:
			break;
	}
	return this;
};

MDDate.prototype.add = function (number, type) {
	switch (type ? type.toLowerCase() : '') {
		case 'hours':
		{
			this.date.setTime(this.date.getTime() + (number * 60 * 60 * 1000));
			break;
		}
		case 'd' :
		case 'day' :
		case 'days' :
		{
			this.date.setDate(this.date.getDate() + number);
			break;
		}
		case 'week' :
		case 'weeks':
		{
			this.date.setDate(this.date.getDate() + (number * 7));
			break;
		}
		case 'month' :
		case 'months' :
		{
			this.date.setMonth(this.date.getMonth() + number);
			break;
		}
		case 'year' :
		case 'years' :
		{
			this.date.setFullYear(this.date.getFullYear() + number);
			break;
		}
		default:
			break;
	}
	return this;
};

MDDate.prototype.diff = function (mdDate, type) {
	let diff = 0;
	switch (type ? type.toLowerCase() : '') {
		case 'days' :
		{
			const diffTime = Math.abs(this.date - mdDate.date);
			diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // diff in Days
			break;
		}
		default:
			break;
	}
	return diff;
};

MDDate.prototype.isSame = function (mdDate, type) {
	let isSame = false;
	switch (type ? type.toLowerCase() : '') {
		case 'day' :
		{
			const date1 = new Date(this.date);
			const date2 = new Date(mdDate.date);
			date1.setHours(0, 0, 0, 0); // set time zero to not consider time while comparision
			date2.setHours(0, 0, 0, 0); // set time zero to not consider time while comparision
			isSame = date1.getTime() === date2.getTime();
			break;
		}
		default:
			break;
	}
	return isSame;
};

MDDate.prototype.format = function (format) {
	const dateToProcess = this.date;
	const _ = this.isUTC ? 'getUTC' : 'get';

	const date = dateToProcess[`${_}Date`]();
	const day = dateToProcess[`${_}Day`]();
	const month = dateToProcess[`${_}Month`]();
	const year = dateToProcess[`${_}FullYear`]();
	const hours = dateToProcess[`${_}Hours`]();
	const minutes = dateToProcess[`${_}Minutes`]();
	const seconds = dateToProcess[`${_}Seconds`]();
	const miliSeconds = dateToProcess[`${_}Milliseconds`]();
	format = format || DEFAULT_FORMAT;

	const flags = {
		D: date,
		DD: pad(date),
		DDD: i18n.dayNames[day],
		DDDD: i18n.dayNames[day + 7],
		M: month + 1,
		MM: pad(month + 1),
		MMM: i18n.monthNames[month],
		MMMM: i18n.monthNames[month + 12],
		YY: String(year).slice(2),
		YYYY: year,
		h: hours % 12 || 12,
		hh: pad(hours % 12 || 12),
		H: hours,
		HH: pad(hours),
		m: minutes,
		mm: pad(minutes),
		s: seconds,
		ss: pad(seconds),
		l: pad(miliSeconds, 3),
		L: pad(miliSeconds > 99 ? Math.round(miliSeconds / 10) : miliSeconds),
		a: hours < 12 ? 'am' : 'pm',
		aa: hours < 12 ? 'am' : 'pm',
		A: hours < 12 ? 'AM' : 'PM',
		AA: hours < 12 ? 'AM' : 'PM',
		z: this.isUTC ? 'UTC' : getTimeZone(this)
	};

	return format.replace(token, $0 => {
		return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
	});
};

MDDate.prototype.isValid = function () {
	return !isNaN(Date.parse(this.date));
};

MDDate.prototype.isBefore = function (mdDate) {
	const date1 = new Date(this.date).getTime();
	const date2 = new Date(mdDate.date).getTime();
	return date1 < date2;
};

MDDate.prototype.isAfter = function (mdDate) {
	const date1 = new Date(this.date).getTime();
	const date2 = new Date(mdDate.date).getTime();
	return date1 > date2;
};

MDDate.prototype.tz = function (timeZone) {
	const dateStr = new Date(this.date).toLocaleString('en', { timeZone });
	this.date = new Date(dateStr); // Update date after converted
	this.convertedToDiffTimeZone = true;
	this.timeZone = timeZone;
	return this;
};

MDDate.prototype.utc = function () {
	this.isUTC = true;
	return this;
};

MDDate.prototype.clone = function () {
	const clonedMdDate = new MDDate(this.date);
	clonedMdDate.timeZone = this.timeZone;
	clonedMdDate.convertedToDiffTimeZone = this.convertedToDiffTimeZone;
	clonedMdDate.isUTC = this.isUTC;
	clonedMdDate.initialDate = this.initialDate;
	return clonedMdDate;
};

MDDate.prototype.from = function (mdDate) {
	if (!this.isValid()) {
		return `From: ${invalidDate()}`;
	}
	if (!mdDate.isValid()) {
		return `To: ${invalidDate()}`;
	}

	let diffRes = {};
	if (this.isBefore(mdDate)) {
		diffRes = datesDifference(this, mdDate);
	} else {
		diffRes = datesDifference(mdDate, this);
	}

	return getRelativeTimeStr(diffRes);
};

const mdDate = (_date, useProvidedDateOnly) => new MDDate(_date, useProvidedDateOnly);

export const isValidDatePattern = (date, format) => {
	let datePatternRegex = '';
	switch (format) {
		case 'YYYY-MM-DD':
			datePatternRegex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
			break;
		default:
			datePatternRegex = /^$/;
			break;
	}

	// Check if the date string format is a match
	const matchArray = date.match(datePatternRegex);
	if (matchArray === null) {
		return false;
	}

	return !isNaN(Date.parse(date));
};

export default mdDate;
