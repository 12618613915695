import Vue from 'vue';
import importedFormatPlugin from '_plugins_/format';
import importedHttpPlugin from '_plugins_/http';
import importedODAPlugin from '_plugins_/oda';
import importedWidgetEventPlugin from '_plugins_/widgetEvent';
import globalHelpers from '_plugins_/globalHelpers';
import {
	BootstrapVue,
	BootstrapVueIcons
} from 'bootstrap-vue';

const create = (appId, vueComponent) => {
	Vue.use(importedFormatPlugin);
	Vue.use(importedHttpPlugin);
	Vue.use(importedODAPlugin);
	Vue.use(importedWidgetEventPlugin);
	Vue.use(globalHelpers);
	Vue.use(BootstrapVue);
	Vue.use(BootstrapVueIcons);

	/**
	* @Styles=false
	*/
	F2.Apps[appId] = class F2Class {
		constructor(appConfig, appContent, root) {
			this.appConfig = appConfig || {};
			this.appContent = appContent;
			this.root = root;
		}

		init() {

			const appContext = this.appConfig.context || {};
			const appIdNew = appId.indexOf('_test') !== -1 ? `${appId} ${appId.replace('_test', '')}` : appId;

			// eslint-disable-next-line no-new
			this.instance = new Vue({
				el: this.root.querySelector(`.${appId}`),
				render: h => h(vueComponent, { class: `${appIdNew} f2-app-container`, props: appContext })
			});
		}

		destroy() {
			this.instance.$destroy();
			this.instance = null;
		}
	};
};

export default {
	create
};
