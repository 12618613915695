/**
 * Use as a Vue plugin:
 *
 * import globalHelpers from '_share_/plugins/globalHelpers';
 * Vue.use(globalHelpers);
 *
 * In component files:
 * Within <template>: $globalHelpers.<FUNCTION>
 * Within <script>: this.$globalHelpers.<FUNCTION>
 *
 * Use elsewhere:
 *
 * import globalHelpers from '_share_/plugins/globalHelpers';
 * const $globalHelpers = globalHelpers.globalHelpers;
 * Call with $globalHelpers.<FUNCTION>
 *
 */

const globalHelpers = {
	refreshTokenAction,
	getRefreshedToken,
	finallyStep
};

let refreshedToken = '';

function refreshTokenAction() {
	refreshedToken = window.localStorage.getItem('client-access-token');
}

function getRefreshedToken() {
	refreshTokenAction();
	return refreshedToken;
}

// eslint-disable-next-line
function finallyStep(step, that) {
	switch (step) {
		case 'loadingDetails':
			that.isLoadingDetails = false;
			break;
		case 'loading':
			that.isLoading = false;
			break;
		case 'initted':
			that.isLoading = false;
			that.$nextTick(() => { that.isInitted = true; });
			break;
		default:
			break;
	}
}

export default {
	install(Vue) {
		Object.defineProperty(Vue.prototype, '$globalHelpers', { value: globalHelpers });
	},
	globalHelpers
};
